import React, { Component } from "react";
import api from "./../lib/api";
import { Modal } from "../lib/components/Modal";
import { Badge } from "react-bootstrap";

export class Topcontent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { latest_version, openChangelog } = this.state;
    return (
      <div className="d-sm-none d-md-block">
        <div
          className="right-align"
          style={{ display: "flex", fontSize: 13, margin: "8px" }}
        >
          <Badge pill variant="primary">
            {this.props.user.email}
          </Badge>
        </div>
      </div>
    );
  }
}
