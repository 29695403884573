import React from "react";

import ReactGA from "react-ga";
import FinanceApp from "./FinanceApp";
import MessagingApp from "./MessagingApp";

function initializeReactGA() {
  ReactGA.initialize("UA-155323455-1");
}

function App() {
  initializeReactGA();
  return (
    <div>
      <FinanceApp></FinanceApp>
      <MessagingApp></MessagingApp>
    </div>
  );
}

export default App;
