import React, { Component } from "react";
import cx from "classnames";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";

import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw
} from "draft-js";

export class MessageItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      senderId: this.props.message.sender_id,
      senderName: this.props.message.sender_name,
      messageTime: this.props.message.created_at,
      text: this.props.message.body_html,
      userId: this.props.user.id,
      rawContent: this.props.message.content,
      editorState: EditorState.createWithContent(
        convertFromRaw(this.props.message.content)
      )
    };
  }

  render() {
    const editorState = EditorState.createWithContent(
      convertFromRaw(this.props.message.content)
    );

    const { senderId, senderName, text, messageTime, userId } = this.state;
    const sendTime = moment(messageTime).fromNow();
    const longFormSend = moment(messageTime);
    return (
      <div>
        <Tooltip title={longFormSend.toString()}>
          <span className="time-label">{sendTime}</span>
        </Tooltip>
        <span className="sender-label">{senderName}</span>
        <div
          className={cx("message-item", {
            "self-sender": senderId === userId
          })}
        >
          <Editor editorState={editorState} readOnly={true} />
        </div>
      </div>
    );
  }
}
