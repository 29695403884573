//import {api_url} from "../constants";
import axios from "axios";
import Cookie from "js-cookie";

const api_url = process.env.REACT_APP_API_URL;

export default class WPFApi {
  static check_logged_in = function(response) {
    if (response.status >= 401 && response.status <= 405) {
      window.location = "/login";
    }
  };

  static setToken(token) {
    Cookie.set("wpf-token", token);
  }

  static hasLoginToken() {
    const token = Cookie.get("wpf-token");
    if (token === null || token === undefined) {
      window.location = "/login";
    }
  }

  static getConfig() {
    const token = Cookie.get("wpf-token");
    return { headers: { Authorization: `${token}` } };
  }

  static isLoggedIn() {
    const token = Cookie.get("wpf-token");
    return !!token;
  }

  static getUserInfo(handler, onError) {
    axios
      .get(`${api_url}/user`, WPFApi.getConfig())
      .then(function(response) {
        //WPFApi.check_logged_in(response)
        handler(response.data);
      })
      .catch(function(err) {
        onError(err);
      });
  }

  static getUserConnectionsFromAuthLink() {}

  static getUserConnections(handler) {
    axios
      .get(`${api_url}/user/connections`, WPFApi.getConfig())
      .then(function(response) {
        //WPFApi.check_logged_in(response)
        handler(response.data);
      });
  }

  static createUserConnection(connectionData, onSuccess, onError) {
    axios
      .post(`${api_url}/auth/connection`, connectionData, WPFApi.getConfig())
      .then(function(response) {
        console.log("Bank account linked!");
        onSuccess(response.data);
      })
      .catch(function(error) {
        console.log(error);
        onError(error);
      });
  }

  static sendUserEmailNow(onSuccess) {
    const sendData = { send_email: true };
    axios
      .post(`${api_url}/weekly/send_now`, sendData, WPFApi.getConfig())
      .then(function(response) {
        onSuccess();
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  static getUserEmailsSent(handler) {
    axios
      .get(`${api_url}/user/weeklys`, WPFApi.getConfig())
      .then(function(response) {
        //WPFApi.check_logged_in(response)
        handler(response.data);
      });
  }

  static setUserEnabledHistoric(userId, value, handler) {
    axios
      .get(`${api_url}/enable-history?user=${userId}&update=${value}`)
      .then(function(response) {
        handler(response.data);
      });
  }

  static getUserMyWeek(handler) {
    axios
      .get(`${api_url}/user/my-week`, WPFApi.getConfig())
      .then(function(response) {
        handler(response.data);
      });
  }
}
