import {forEach} from "react-bootstrap/cjs/ElementChildren";


export default class TransactionEmoji {

    static categoryToEmojiSymbol(name, categories) {

        if (!categories) {
            return ''
        }

        if (name.toLowerCase().indexOf('cafe') >= 0) {
            return '☕️'
        } else if (name.toLowerCase().indexOf('bar') >= 0) {
            return '🍻'
        }

        let symbol;
        categories.forEach(function (cat) {
            switch (cat.toLowerCase()) {
                case 'supermarkets and groceries':
                    symbol = "🌽";
                    break;
                case 'shops':
                    symbol = "🛍";
                    break;
                case 'travel':
                    symbol = "🚙";
                    break;
                case 'restaurants':
                    symbol =  "🍲";
                    break;
                case 'taxi':
                    symbol = "🚙";
                    break;
                case 'coffee shop':
                    symbol = '☕️';
                case 'transfer':
                case 'Payment':
                    symbol = '💳';
                case 'service':
                    symbol = '🖥️';
                default:
                    break;
            }
        });

        return symbol;

    }
}