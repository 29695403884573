import React, { Component } from "react";
import PlaidLink from "react-plaid-link";
import { Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import WPFApi from "../api/WPFApi";
import axios from "axios";
import './link.css'
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Moment from "react-moment";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles({
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

class PlaidLinkComp extends Component {

    constructor(props) {
        super(props);

        if (props.match && props.match.params) {
            this.state = {
                transactions: [],
                connections: [],
                email: '',
                user_auth: props.match.user,
                token: props.match.token,
                update: props.match.update
            };
        }
        else {
            this.state = {
                transactions: [],
                connections: [],
                email: '',
            };
        }

        this.handleEmailSubmit = this.handleEmailSubmit.bind(this);
        this.handleOnSuccess = this.handleOnSuccess.bind(this);
        this.refreshUserConnections = this.refreshUserConnections.bind(this);

        this.refreshUserConnections();

    }

    refreshUserConnections() {
        const self = this;

        if (this.state.user_auth && this.state.token) {
            WPFApi.setToken(this.state.token);
        }

        WPFApi.getUserConnections(function(data) {
            self.setState({connections: data.connections})
        })
    }

    handleOnSuccess(public_token, metadata) {

        const connectionData = {
            public_token: public_token,
            info: metadata
        };

        WPFApi.createUserConnection(connectionData, function(responseData) {
            //TODO: show an alert to send an email now?

            window.location = '/profile'

        });

    }

    handleEmailSubmit(event) {
        event.preventDefault();
    }

    handleOnExit() {
        // handle the case when your user exits Link
        // For the sake of this tutorial, we're not going to be doing anything here.
    }


    renderConnectionTable() {
        const connections = this.state.connections || [];
        return (
            <div>
                <TableContainer component={Paper} style={{ width: 750, padding: '12px'}}>
                    <Table aria-label="Your Connected Accounts">
                        <TableHead>
                            <TableRow>
                                <TableCell>Connected On</TableCell>
                                <TableCell align="left">Institution</TableCell>
                                <TableCell align="left">Accounts</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {connections.map(row => row.info ? (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        <Moment format="YYYY/MM/DD">{row.created_at}</Moment>
                                    </TableCell>
                                    <TableCell align="left">{row.info.institution.name}</TableCell>

                                    <TableCell align="left">
                                        {row.info.accounts.map(function(acc) {
                                            return (
                                                <li style={{margin: 0}}>{acc.name}</li>
                                            );
                                        })}
                                    </TableCell>
                                    {
                                        row.has_issue ? (
                                            <TableCell align="center">
                                                <PlaidLink
                                                    clientName="Weekly Peek Finance"
                                                    env="development"
                                                    product={["auth", "transactions"]}
                                                    publicKey="6a498989521f8c2f92bd7d77bd59f7"
                                                    onExit={this.handleOnExit}
                                                    onSuccess={this.handleOnSuccess}
                                                    className="App-link">
                                                    Connect your bank account!
                                                </PlaidLink>
                                            </TableCell>
                                        ) : null
                                    }

                                </TableRow>
                            ) : null)}
                        </TableBody>
                    </Table>
                </TableContainer>

            </div>
        )
    }


    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleClose = (sendEmailNow) => {
        if (sendEmailNow === true) {
            WPFApi.sendUserEmailNow()
        }
        else {
            this.setState({open: false})
        }
    };

    render() {
        const hideTransactions = this.state.notConnected;
        const connections = this.state.connections;
        const open = this.state.open;

        return (
            <div className="main" style={{margin: '24px'}}>

                <Dialog
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Send your first transaction email now?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Your transaction email will normally be sent every Sunday morning. But would you like us to send an email right  now so you can see how it will look?
                            This email will contain all your transactions from the previous week.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleClose(true)} color="primary">
                            Send Now
                        </Button>
                        <Button onClick={() => this.handleClose(false)} color="primary" autoFocus>s
                            Don't Send Now
                        </Button>
                    </DialogActions>
                </Dialog>

                <div style={{width: '50%', float: 'left', marginTop: '100px'}}>
                    <PlaidLink
                        clientName="Weekly Peek Finance"
                        env="development"
                        product={["transactions"]}
                        publicKey="6a498989521f8c2f92bd7d77bd59f7"
                        onExit={this.handleOnExit}
                        onSuccess={this.handleOnSuccess}
                        className="App-link">
                        Connect your bank account!
                    </PlaidLink>
                </div>


                <div>
                  <h4> Your currently connected accounts:</h4>
                    {
                        connections.length > 0 ? this.renderConnectionTable() : (<p>Connect your first account!</p>)
                    }
                </div>

                <Button
                    component={RouterLink} to={"/profile"}
                    color="link"
                    size="small">
                    {"< Back to Profile"}
                </Button>


            </div>
        );
    }
}

export default PlaidLinkComp;
