import React, { Component } from "react";
import { toast } from "react-toastify";

export class SubmitFeedback extends Component {
  submit = () => {
    console.log("feedback submitted :/");
  };

  render() {
    return (
      <div>
        <br />
        Submit feedback to WeeklyPeek
        <br />
        <br />
        <div className="input-group">
          <input type="text" className="form-control" />
        </div>
        <div>
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={this.submit.bind(this)}
          >
            Submit
          </button>
        </div>
        <br />
      </div>
    );
  }
}
