import React, { Component } from "react";
import { Link } from "react-router-dom";
import MessagingApi from "../../api/MessagingApi";
import { MessageItem } from "../../components/MessageItem";
import { WPRichTextEditor } from "../../components/WPRichTextEditor";
import { toast } from "react-toastify";
import moment from "moment";
import cx from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Button from "react-bootstrap/Button";
import Tooltip from "@material-ui/core/Tooltip";

import { InviteToGroup } from "../../lib/components/InviteToGroup";
import { Modal } from "../../lib/components/Modal";

export class ThreadList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      threads: [],
      selected: "",
      user: this.props.user
    };

    this.setSelected = this.setSelected.bind(this);
    this.onSendMessage = this.onSendMessage.bind(this);
    this.refreshThreads = this.refreshThreads.bind(this);
  }

  componentDidMount() {
    this.refreshThreads();
  }

  refreshThreads() {
    const self = this;
    MessagingApi.getUserThreads(
      function(responseData) {
        self.setState({ threads: responseData.threads, loading: false });
      },
      function(error) {
        self.setState({ loading: false });
        toast.error(error);
      }
    );
  }

  setSelected = th => {
    const self = this;
    let thread_id = th.thread_id || th.id; //This is messy but for some reason we are passing groups and threeads in here causing the neeed for this
    MessagingApi.getThreadDetails(thread_id, function(thread_info) {
      self.setState({
        seleectedThread: thread_info,
        selectedId: thread_info.id
      });
    });
  };

  onSendMessage = () => {
    let { seleectedThread } = this.state;
    this.refreshThreads();
    this.setSelected(seleectedThread);
  };

  render() {
    let {
      hasNext,
      threads,
      loading,
      selectedId,
      seleectedThread,
      user
    } = this.state;
    let showNoMsg = false;
    if (seleectedThread) {
      if (seleectedThread.messages.length === 0) {
        showNoMsg = true;
      }
    }

    return (
      <div>
        <div className="d-sm-none d-md-block thread-header">
          <h3>WeeklyPeek Threads</h3>
          <div className="row" style={{ margin: "0px", padding: "0px" }}>
            <div
              className="col-md-6"
              style={{ marginLeft: "0px", paddingLeft: "0px" }}
            >
              <p className="secondary">
                Current Week ends{" "}
                {moment().day() == 0
                  ? moment()
                      .endOf("day")
                      .fromNow()
                  : moment()
                      .endOf("week")
                      .add("day", 1)
                      .fromNow()}
              </p>
            </div>
            <div className="col-md-6">
              {seleectedThread && (
                <Button
                  className="btn"
                  variant="link"
                  size="sm"
                  style={{ float: "right", marginTop: "-5px" }}
                  onClick={() => this.setState({ inviteModalOpen: true })}
                >
                  Invite to <strong>{seleectedThread.group_name}</strong>
                </Button>
              )}
            </div>
          </div>
        </div>
        {loading && (
          <div className="loading-overlay">
            <div></div>
          </div>
        )}
        {threads && threads.length == 0 && (
          <div>
            <p>
              It doesn't look like you are a member of any WeeklyPeek Groups
              yet! <br />
            </p>

            <p>
              To start sending and receieving weekly updates, you need to either
              setup or join a group.
            </p>

            <div className="mb-3">
              <Link to="/messaging/groups">
                <button className="btn btn-sm btn-success">
                  Create a New Group
                </button>
              </Link>
            </div>
            <div>
              <p>
                If you have already received an invite from someone you can{" "}
                <Link to="/messaging/groups/join" className="btn-link">
                  Join a group
                </Link>
              </p>
            </div>
          </div>
        )}
        {threads && threads.length > 0 && (
          <div
            className="row threads-page-container"
            style={{ marginTop: "4px" }}
          >
            <div className="col-sm-12 col-md-3 p-0 thread-list">
              {threads.map(thread => (
                <div
                  className={cx({
                    "thread-list-item": true,
                    "thread-selected": selectedId === thread.thread_id
                  })}
                  onClick={() => this.setSelected(thread)}
                >
                  {thread.has_responded && (
                    <Tooltip title="You responded to this thread">
                      <span className="thread-fill-icon">
                        <CheckCircleIcon />
                      </span>
                    </Tooltip>
                  )}
                  <h5>{thread.name}</h5>
                  <Tooltip
                    title="This shows how many people have sent in their responses for this week"
                    enterDelay={1200}
                  >
                    <p className="response-info">
                      {thread.message_count ?? 0} / {thread.member_count ?? 0}{" "}
                      responses
                    </p>
                  </Tooltip>
                </div>
              ))}
              {threads.length < 3 && (
                <div className="thread-list-item new-thread-item">
                  <Link to="/messaging/groups">
                    <span>+ Create new thread</span>
                  </Link>
                </div>
              )}
              {threads && threads.length == 3 && (
                <div style={{ textAlign: "center" }}>
                  <span className="text-secondary">3/3 Groups used</span>
                </div>
              )}
            </div>
            <div className="col-md-9 col-sm-12 thread-details">
              {seleectedThread &&
                seleectedThread.messages &&
                seleectedThread.messages.map(msg => (
                  <MessageItem message={msg} user={user} />
                ))}

              {showNoMsg && (
                <div
                  className="has-responded-wrapper"
                  style={{ minHeight: "100px" }}
                >
                  <p>
                    No one has responded yet for this week. Submit your response
                    below.
                  </p>
                </div>
              )}

              {seleectedThread && !seleectedThread.has_responded && (
                <div className="editor-wrapper" style={{ minHeight: "100px" }}>
                  <WPRichTextEditor
                    threadId={selectedId}
                    onSendMessage={this.onSendMessage}
                  />
                </div>
              )}
              {seleectedThread && seleectedThread.has_responded && (
                <div
                  className="has-responded-wrapper"
                  style={{ minHeight: "80px", paddingTop: "14px" }}
                >
                  <p>
                    Nice job, you responded{" "}
                    {moment(seleectedThread.user_responded_at).fromNow()}
                  </p>
                </div>
              )}
              {this.state.inviteModalOpen && (
                <Modal
                  onDismiss={() => this.setState({ inviteModalOpen: false })}
                  hideFooter={true}
                >
                  <InviteToGroup
                    user={this.props.user}
                    groupId={seleectedThread.group_id}
                    inviteLink={seleectedThread.invite_link}
                    onInviteSent={() =>
                      this.setState({ inviteModalOpen: false })
                    }
                  />
                </Modal>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
