import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Switch from '../elements/Switch';
import Button from '../elements/Button';

const propTypes = {
  ...SectionTilesProps.types,
  pricingSwitcher: PropTypes.bool
}

const defaultProps = {
  ...SectionTilesProps.defaults,
  pricingSwitcher: false
}

class Pricing extends React.Component {

  state = {
    pricingSwitchOn: true
  }

  handlePricingSwitch = (e) => {
    this.setState({ pricingSwitchOn: e.target.checked });
  }

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      pricingSwitcher,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'pricing section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'pricing-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap illustration-section-04',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Pricing',
      paragraph: 'Get started with a 30 day free trial. Save by going with an annual payment, or have the flexibility to cancel any time with monthly.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            {pricingSwitcher &&
              <div className="pricing-switcher center-content">
                <Switch
                  checked={this.state.pricingSwitchOn}
                  onChange={this.handlePricingSwitch}
                  rightLabel="Billed Annually">
                    Billed Monthly
                </Switch>
              </div>
            }
            <div className={tilesClasses}>

              <div className="tiles-item reveal-from-top">
                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-16 mb-24">
                      <div className="pricing-item-price mb-4">
                        {/*<span className="pricing-item-price-currency h2 text-color-low">$</span>*/}
                        {/*<span className="pricing-item-price-amount h1">{this.state.pricingSwitchOn ? '19.99' : '1.99'}</span>*/}
                        <span className="pricing-item-price-amount h1">Free Beta!</span>
                      </div>
                      {/*<div className="text-color-low text-xs">*/}
                      {/*  per {this.state.pricingSwitchOn ? 'year': 'month' }, billed {this.state.pricingSwitchOn ? 'annually (save 17%)': 'monthly' }*/}
                      {/*</div>*/}
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title text-xs fw-700 text-color-high mb-24">
                        What's included
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                        <li className="is-checked">Full transaction list</li>
                        <li className="is-checked">Spending by category</li>
                        <li className="is-checked">Subscription payment notices</li>
                        <li className="is-checked">Weekly budget alerts</li>
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                    <Button tag="a" color="primary" wide href="/signup">Get Started</Button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

Pricing.propTypes = propTypes;
Pricing.defaultProps = defaultProps;

export default Pricing;