import React from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";

export function GroupMemberEdit() {
  const isValidEmail = () => {};

  return (
    <div className="row">
      <div className="member-item col-md-9">
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">Email</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder="Friend's or Loved one's Email"
            aria-label="Email"
            aria-describedby="basic-addon1"
          />
        </InputGroup>
      </div>
      <div className="member-item col-md-3">
        <Button className="btn btn-primary">Add</Button>
      </div>
    </div>
  );
}
