import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Title from "../components/Title";
import { Button } from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Moment from "react-moment";

import axios from "axios";
import { api_url } from "../constants";
import WPFApi from "../api/WPFApi";
import ReactGA from "react-ga";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import PlaidLink from "react-plaid-link";

import ErrorIcon from "@material-ui/icons/Error";
import { green, red } from "@material-ui/core/colors";

function preventDefault(event) {
  event.preventDefault();
}

const styles = theme => ({
  context: {
    flex: 1
  }
});

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emails: [],
      open: false
    };

    this.getUserInfo = this.getUserInfo.bind(this);
    this.refreshUserProfile = this.refreshUserProfile.bind(this);
    this.handleSendEmailNow = this.handleSendEmailNow.bind(this);
    this.toggleEnableHistoric = this.toggleEnableHistoric.bind(this);
    this.handleOnSuccess = this.handleOnSuccess.bind(this);

    this.getUserInfo();
    this.refreshUserProfile();

    ReactGA.pageview("/profile");
  }

  handleSendEmailNow(event) {
    preventDefault(event);
    let self = this;
    WPFApi.sendUserEmailNow(function() {
      self.refreshUserProfile();
    });
  }

  handleAddConnection() {
    window.location = "/start";
  }

  getUserInfo() {
    let self = this;
    WPFApi.getUserInfo(function(info) {
      self.setState({ user: info.id, userInfo: info });
    });
  }

  refreshUserProfile() {
    let self = this;
    WPFApi.getUserConnections(function(data) {
      self.setState({ connections: data.connections });
    });

    WPFApi.getUserEmailsSent(function(data) {
      self.setState({ emails: data.emails, emailCount: data.count });
    });

    // TODO: decide whether to send an email here
  }

  toggleEnableHistoric() {
    let self = this;
    let userId = self.state.user;
    let historic = !self.state.userInfo.historic_enabled;
    WPFApi.setUserEnabledHistoric(userId, historic, function(data) {
      self.getUserInfo();
    });
  }

  handleOnSuccess(public_token, metadata) {
    const connectionData = {
      public_token: public_token,
      info: metadata
    };

    WPFApi.createUserConnection(connectionData, function(responseData) {
      //TODO: show an alert to send an email now?

      window.location = "/profile";
    });
  }

  renderEmailSentTable() {
    const emails = this.state.emails;
    return (
      <div>
        <TableContainer
          component={Paper}
          style={{ width: 750, padding: "12px" }}
        >
          <Table aria-label="Weekly Emails sent">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell align="left">Result</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {emails.map(row => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.created_at}
                  </TableCell>
                  <TableCell align="left">
                    {row.result.replace("JobResult.", "")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  renderConnectionTable() {
    const connections = this.state.connections || [];
    return (
      <div>
        <TableContainer
          component={Paper}
          style={{ width: 750, padding: "12px" }}
        >
          <Table aria-label="Your Connected Accounts">
            <TableHead>
              <TableRow>
                <TableCell>Connected On</TableCell>
                <TableCell align="left">Institution</TableCell>
                <TableCell align="left">Accounts</TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {connections.map(row =>
                row.info ? (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.has_issue ? (
                        <ErrorIcon
                          style={{ color: red[500], marginTop: "-25px" }}
                        ></ErrorIcon>
                      ) : null}
                      <Moment format="YYYY/MM/DD">{row.created_at}</Moment>
                    </TableCell>
                    <TableCell align="left">
                      {row.info.institution.name}
                    </TableCell>

                    <TableCell align="left">
                      {row.info.accounts.map(function(acc) {
                        return <li style={{ margin: 0 }}>{acc.name}</li>;
                      })}
                    </TableCell>
                    <TableCell align="center">
                      {row.has_issue ? (
                        <PlaidLink
                          clientName="Weekly Peek Finance"
                          env="development"
                          product={["auth", "transactions"]}
                          publicKey="6a498989521f8c2f92bd7d77bd59f7"
                          token={row.public_token}
                          onExit={this.handleOnExit}
                          onSuccess={this.handleOnSuccess}
                          className="App-link"
                        >
                          Fix Account Issue
                        </PlaidLink>
                      ) : (
                        <div></div>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <button className="btn btn-small btn-link">Remove</button>
                    </TableCell>
                  </TableRow>
                ) : null
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = sendEmailNow => {
    if (sendEmailNow === true) {
      WPFApi.sendUserEmailNow();
    } else {
      this.setState({ open: false });
    }
  };

  render() {
    const { classes } = this.props;
    const transactionData = [];
    const emails = this.state.emails;
    const emailCount = this.state.emailCount;
    const connections = this.state.connections || [];
    var historicButtonTitle = "";
    var historicButtonVariant = "contained";
    if (this.state.userInfo) {
      historicButtonTitle =
        this.state.userInfo.historic_enabled === true
          ? "Disable Historic Processing"
          : "Enable Historic Processing";
      historicButtonVariant =
        this.state.userInfo.historic_enabled === true
          ? "outlined"
          : "contained";
    }

    const open = this.state.open;

    return (
      <div className="transaction-page">
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Send your first transaction email now?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Your transaction email will normally be sent every Sunday morning.
              But would you like us to send an email right now so you can see
              how it will look? This email will contain all your transactions
              from the previous week.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose(true)} color="primary">
              Send Now
            </Button>
            <Button
              onClick={() => this.handleClose(false)}
              color="primary"
              autoFocus
            >
              s Don't Send Now
            </Button>
          </DialogActions>
        </Dialog>

        <Title>Your Account Connections</Title>

        {connections.length == 0 ? (
          <div>
            <Typography component="p" variant="p">
              {connections.length == 0
                ? "You have not connected any of your accounts yet. Please connect an account to get started with your weekly spending emails."
                : `You've connected ${connections.length} accounts`}
            </Typography>

            <Button
              component={RouterLink}
              to="/start"
              style={{ color: "#fff", textDecoration: "none", margin: "10px" }}
              color="primary"
              size="small"
              variant="contained"
            >
              Connect a Spending Account
            </Button>
          </div>
        ) : null}

        {connections.length > 0 ? this.renderConnectionTable() : null}

        {connections.length > 0 ? (
          <Button
            component={RouterLink}
            to="/start"
            style={{ margin: "10px" }}
            color="link"
            variant="outlined"
            size="small"
          >
            Connect Another Account
          </Button>
        ) : null}

        <div>
          <Title>Settings</Title>

          <div>
            <Button
              color="primary"
              size="small"
              variant="contained"
              style={{ margin: "10px" }}
              onClick={this.toggleEnableHistoric}
            >
              {historicButtonTitle}
            </Button>
          </div>
        </div>

        <Title>Your Weekly Spending Emails</Title>

        <Typography component="p">
          {emails.length == 0
            ? "No Emails have been sent. Click below to send your first one now"
            : `You've had ${emailCount} weekly transaction emails sent`}
        </Typography>
        <div>
          <Button
            color="primary"
            size="small"
            variant="contained"
            style={{ margin: "10px" }}
            onClick={this.handleSendEmailNow}
          >
            Send your weekly Transaction Email Now
          </Button>
        </div>
        {emails.length > 0 ? this.renderEmailSentTable() : null}
      </div>
    );
  }
}

export default withStyles(styles)(Profile);
