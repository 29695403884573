import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import "babel-polyfill";

import { resetContext, getContext } from "kea";
import listenersPlugin from "kea-listeners";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

//import './App.css';
import "./assets/scss/style.scss";

resetContext({
  createStore: {
    // additional options (e.g. middleware, reducers, ...)
  },
  plugins: [listenersPlugin]
});

ReactDOM.render(
  <Provider store={getContext().store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
