import React from "react";
import ReactDOM from "react-dom";
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw
} from "draft-js";
import Button from "react-bootstrap/Button";
import MessagingApi from "../api/MessagingApi";
import { toast } from "react-toastify";

const isValidText = text => {
  if (text.length > 0) {
    return true;
  } else {
    return false;
  }
};

export class WPRichTextEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editorState: EditorState.createEmpty() };

    this.focus = () => this.refs.editor.focus();
    this.handleKeyCommand = command => this._handleKeyCommand(command);
    this.onTab = e => this._onTab(e);
    this.toggleBlockType = type => this._toggleBlockType(type);
    this.toggleInlineStyle = style => this._toggleInlineStyle(style);
    this.send = this.send.bind(this);
    this.onChange = this.onChange.bind(this);
    this.loadContent = this.loadContent.bind(this);

    const threadId = this.props.threadId;
    const content = window.localStorage.getItem(threadId);

    if (content) {
      this.state.editorState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(content))
      );
    } else {
      this.state.editorState = EditorState.createEmpty();
    }
    this.state.prevThreadId = threadId;
  }

  cacheContent = content => {
    window.localStorage.setItem(
      this.props.threadId,
      JSON.stringify(convertToRaw(content))
    );
  };

  loadContent = threadId => {
    const content = window.localStorage.getItem(threadId);
    if (content) {
      this.setState({
        editorState: EditorState.createWithContent(
          convertFromRaw(JSON.parse(content))
        )
      });
    } else {
      this.setState({ editorState: EditorState.createEmpty() });
    }
  };

  saveContent = content => {
    let data = {
      thread_id: this.props.threadId,
      content: convertToRaw(content)
    };

    if (!data.content.blocks || !data.content.blocks[0].text) {
      toast.error("Please type your update to send");
      return;
    }
    let self = this;
    MessagingApi.submitNewMessage(
      data,
      function(response) {
        self.props.onSendMessage();
      },
      function(error) {
        toast.error("Could not send message!");
        console.dir(error);
      }
    );
  };

  send = () => {
    const { editorState } = this.state;
    const threadId = this.props.threadId;
    var contentState = editorState.getCurrentContent();
    window.localStorage.removeItem(threadId);
    this.saveContent(contentState);
  };

  onChange = editorState => {
    const contentState = editorState.getCurrentContent();
    this.cacheContent(contentState);
    this.setState({
      editorState
    });
  };

  _handleKeyCommand(command) {
    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  _onTab(e) {
    const maxDepth = 4;
    this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
  }

  _toggleBlockType(blockType) {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  }

  _toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.threadId != this.props.threadId) {
      this.loadContent(this.props.threadId);
    }
  }

  render() {
    const { editorState, prevThreadId, validText } = this.state;

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = "RichEditor-editor";
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (
        contentState
          .getBlockMap()
          .first()
          .getType() !== "unstyled"
      ) {
        className += " RichEditor-hidePlaceholder";
      }
    }

    return (
      <div className="RichEditor-root">
        <BlockStyleControls
          editorState={editorState}
          onToggle={this.toggleBlockType}
        />
        <InlineStyleControls
          editorState={editorState}
          onToggle={this.toggleInlineStyle}
        />
        <div className={className} onClick={this.focus}>
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.onChange}
            onTab={this.onTab}
            placeholder="Start typing your response below!"
            ref="editor"
            spellCheck={true}
          />
        </div>
        <Button color="primary" onClick={() => this.send()}>
          Send!
        </Button>
      </div>
    );
  }
}

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2
  }
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    default:
      return null;
  }
}

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = e => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    let className = "RichEditor-styleButton";
    if (this.props.active) {
      className += " RichEditor-activeButton";
    }

    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}

const BLOCK_TYPES = [
  { label: "Title", style: "header-three" },
  { label: "Heading", style: "header-four" },
  { label: "Blockquote", style: "blockquote" },
  { label: "List", style: "unordered-list-item" }
];

const BlockStyleControls = props => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

var INLINE_STYLES = [
  { label: "Bold", style: "BOLD" },
  { label: "Italic", style: "ITALIC" },
  { label: "Underline", style: "UNDERLINE" },
  { label: "Monospace", style: "CODE" }
];

const InlineStyleControls = props => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};
