import React, { Component } from "react";
import { BrowserRouter as Router, Link, useParams } from "react-router-dom";

import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import MessagingApi from "../../api/MessagingApi";
import { GroupMemberEdit } from "./GroupMemberEdit";

export class GroupDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      threads: [],
      validated: false,
      emails: []
    };

    console.log("self.");

    //let { id } = useParams();
    let id = "xxx";
    const self = this;
    MessagingApi.getGroupDetails(
      id,
      function(group) {
        self.setState({ group });
      },
      function(error) {
        self.setState({ error: true });
        toast.error("Could not load group!");
      }
    );
  }

  removeEmail() {}

  addEmail() {}

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    console.log("Sending email invites:");
    console.log(this.state.emails);

    MessagingApi.addMembersToGroup(
      {
        emails: this.state.emails
      },
      function(respData) {
        console.log("Got response");
        console.log(respData);
      },
      function(error) {
        console.log("error... :/");
        console.log(error);
      }
    );
  }

  changeHandler = event => {
    const value = event.target.value;

    this.setState({
      currentEmail: value
    });
  };

  render() {
    const { validated, formControls, emails, group, email, error } = this.state;
    return (
      <div>
        {!!group && (
          <div>
            <h3>Add members to {group.name} </h3>
            <div
              style={{ marginTop: "1rem", textAlign: "left" }}
              className="group-member-wrapper"
            >
              {emails.map(email => (
                <GroupMemberEdit email={email} />
              ))}
            </div>
          </div>
        )}
        {!!error && (
          <div>
            <p>Could not load group...</p>
          </div>
        )}
      </div>
    );
  }
}
