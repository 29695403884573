import React from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import WPFApi from "../api/WPFApi";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TransactionEmoji from "../services/TransactionEmoji";
import Emoji from "../components/Emoji";
import transitions from "@material-ui/core/styles/transitions";

const styles = theme => ({
    context: {
        flex: 1,
    },
});

const colWidth2 = {
    width: '2rem'
};

const colWidth4 = {
    width: '4rem'
};

const colWidth6 = {
    width: '6rem'
};

class MyWeekPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            transactions: [],
            loading: true
        };

        this.getTransactions = this.getTransactions.bind(this);

        //Execute the API Request to get weekly transactions
        this.getTransactions();
    }

    getTransactions() {
        let self = this;
        WPFApi.getUserMyWeek(function(info) {
            self.setState({transactions: info.transactions, total: info.spent})
        })

    }


    render() {
        const {transactions, total} = this.state;
        return (
            <div className={'transaction-page'}>
                { !!total && <div style={{paddingTop: '15px'}}>
                    <span className={'body'}
                          style={{margin: '5vw'}}>{transactions.length} transactions in the last week</span>
                    <br/>
                    <span className={'body'} style={{margin: '5vw'}}>${total.toFixed(2)} spent</span>
                </div>
                }
                <TableContainer component={Paper} style={{ width: '80vw', padding: '12px', margin:'5vw'}}>
                    <Table aria-label="Weekly Emails sent">
                        <TableBody>
                            {transactions.map(row => (
                                <TableRow key={row.id} className={'transaction-row'}>
                                    <TableCell>
                                        <Emoji symbol={TransactionEmoji.categoryToEmojiSymbol(row.name, row.category)} size='24px' />
                                    </TableCell>
                                    <TableCell component="th" scope="row" style={{'width': '12rem'}}>
                                        {row.date}
                                    </TableCell>
                                    <TableCell align="left">{(row.name)}</TableCell>
                                    <TableCell align="left">{('$' + row.amount)}</TableCell>
                                    <TableCell align="left">{(row.category.join(', '))}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </div>
        );
    }

}

export default withStyles(styles)(MyWeekPage);