import { kea } from "kea";
import api from "./lib/api";
import WPFApi from "./api/WPFApi";

import { toast } from "react-toastify";

export const userLogic = kea({
  actions: () => ({
    loadUser: true,
    setUser: (user, updateKey) => ({ user: { ...user }, updateKey }), // make and use a copy of user to patch some legacy issues
    userUpdateRequest: (update, updateKey) => ({ update, updateKey }),
    userUpdateSuccess: (user, updateKey) => ({ user, updateKey }),
    userUpdateFailure: (updateKey, error) => ({ updateKey, error })
  }),

  reducers: ({ actions }) => ({
    user: [
      null,
      {
        [actions.setUser]: (_, payload) => payload.user,
        [actions.userUpdateSuccess]: (_, payload) => payload.user
      }
    ]
  }),

  events: ({ actions }) => ({
    afterMount: actions.loadUser
  }),

  listeners: ({ actions }) => ({
    [actions.loadUser]: async () => {
      try {
        WPFApi.getUserInfo(
          function(user) {
            actions.setUser(user);

            if (user && user.id) {
              if (window.weeklypeek) {
                window.weeklypeek.identify(user.distinct_id);
              }
            }
          },
          function(error) {
            console.log("handlinger user get EROR");
          }
        );
      } catch (error) {}
    },
    [actions.userUpdateRequest]: async ({ update, updateKey }) => {
      try {
        const user = await api.update("api/user", update);
        actions.userUpdateSuccess(user, updateKey);
      } catch (error) {
        actions.userUpdateFailure(updateKey, error);
      }
    }
  })
});
