import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { InviteTeam } from "../lib/components/InviteTeam";
import { SubmitFeedback } from "../lib/components/SubmitFeedback";
import { Modal } from "../lib/components/Modal";

class HeadwayWidget extends Component {
  constructor(props) {
    super(props);
    this.showHeadway = this.showHeadway.bind(this);
    this.state = {
      headwayOpen: false
    };
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://cdn.headwayapp.co/widget.js";
    document.head.appendChild(script);

    var config = {
      selector: ".headway-badge",
      position: { y: "top", x: "right" },
      account: "xYY6Bx"
    };

    //console.dir(this.div);
    let self = this;
    script.onload = function() {
      window.Headway.init(config);
      self.setState({ headway: window.Headway });
    };
  }

  showHeadway() {
    const { headway, headwayOpen } = this.state;

    if (headwayOpen) {
      headway.hide();
    } else {
      headway.show();
    }

    this.setState({ headwayOpen: !headwayOpen });
  }

  render() {
    return (
      <div style={{ position: "relative" }} onClick={() => this.showHeadway()}>
        <span className="headway-badge" id="headway-badge">
          What's New
        </span>
      </div>
    );
  }
}

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const user = this.props.user;
    let matches = path =>
      this.props.history.location.pathname.indexOf(path) > -1;

    // TODO: Add a d-sm header nav version of the sidebar....
    return (
      <div
        className="sidebar d-none d-md-block col-md-2 bg-light pt-3"
        style={{ minHeight: "100vh", maxHeight: "100vh" }}
      >
        <div className="row logo-row">
          {/* <img className="logo" src="/static/posthog-logo.png" /> */}
          <div className="posthog-title">WeeklyPeek</div>
        </div>
        {!!user.id && (
          <ul className="nav flex-sm-column">
            <li>
              <NavLink className="nav-link" exact to="/messaging/threads">
                <i className="fi flaticon-mail" /> Threads
              </NavLink>
            </li>
            <li>
              <NavLink className="nav-link" to="/messaging/setup">
                <i className="fi flaticon-settings" /> Settings
              </NavLink>
            </li>
            {/*<li>*/}
            {/*    <NavLink className="nav-link" to="/events">*/}
            {/*        <i className="fi flaticon-zoom-in" /> Projects*/}
            {/*    </NavLink>*/}
            {/*</li>*/}
            {/*{(matches('/action') || matches('/events')) && [*/}
            {/*    <li key="1" className="nav-indent">*/}
            {/*        <NavLink className="nav-link" exact to="/actions">*/}
            {/*            <i className="fi flaticon-click" /> Actions*/}
            {/*        </NavLink>*/}
            {/*    </li>,*/}
            {/*    <li key="2" className="nav-indent">*/}
            {/*        <NavLink className="nav-link" to="/actions/live">*/}
            {/*            <i className="fi flaticon-refresh" /> Live*/}
            {/*            actions*/}
            {/*        </NavLink>*/}
            {/*    </li>,*/}
            {/*]}*/}
          </ul>
        )}
        {!user.id && (
          <ul className="nav flex-sm-column">
            <li>
              <NavLink className="nav-link" exact to="/messaging/login">
                <i className="fi flaticon-mail" /> Login
              </NavLink>
            </li>
            <li>
              <NavLink className="nav-link" exact to="/messaging/signup">
                <i className="fi flaticon-mail" /> Signup
              </NavLink>
            </li>
          </ul>
        )}
        <div className="col-sm-3 col-md-2 invite-team">
          <div className="new-wrapper">
            <HeadwayWidget></HeadwayWidget>
          </div>
          <button
            className="secondary"
            onClick={() => this.setState({ inviteModalOpen: true })}
          >
            Share WeeklyPeek
          </button>
          <button
            className="secondary"
            onClick={() => this.setState({ feedbackModalOpen: true })}
          >
            Submit Feedback
          </button>
          <br />
          <NavLink className="secondary" exact to="/about/messaging">
            Help
          </NavLink>
        </div>
        {this.state.inviteModalOpen && (
          <Modal
            onDismiss={() => this.setState({ inviteModalOpen: false })}
            hideFooter={true}
          >
            <InviteTeam user={this.props.user} />
          </Modal>
        )}
        {this.state.feedbackModalOpen && (
          <Modal
            onDismiss={() => this.setState({ feedbackModalOpen: false })}
            hideFooter={true}
          >
            <SubmitFeedback user={this.props.user} />
          </Modal>
        )}
      </div>
    );
  }
}
export default withRouter(Sidebar);
