import React from "react";
import { useValues } from "kea";
import { InviteTeam } from "../../lib/components/InviteTeam";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";

import { userLogic } from "../../userLogic";

export function Setup() {
  const { user } = useValues(userLogic);

  return (
    <div>
      <h3>Settings</h3>

      <h4>Your Account</h4>

      <div className="settings-seection">
        <p>
          Account email: <strong>{user.email}</strong>
        </p>
        <p>
          Account type: <strong>{user.account_type || "Beta"}</strong>
        </p>
        <p>
          Account Created: <strong>{moment(user.created_at).calendar()}</strong>
        </p>
        <Tooltip title="You will be able to change this setting in a future version of WeeklyPeek">
          <p>
            Receive emails on: <strong>Sunday</strong>
          </p>
        </Tooltip>
      </div>

      <h4>Your Groups</h4>
      <div className="settings-seection">
        <ul>{user.groups && user.groups.map(grp => <li>{grp.name}</li>)}</ul>
      </div>

      {/* <div className="row">
        <div className="col-lg-6">
          <InviteTeam user={user} />
        </div>
      </div> */}
    </div>
  );
}
