import React from "react";
import { useValues } from "kea";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import classNames from "classnames";
import Button from "../components/elements/Button";
import Link from "@material-ui/core/Link";

import { userLogic } from "../userLogic";

import { Topcontent } from "../layouts/Topcontent";

export function AboutPage() {
  const { user } = useValues(userLogic);

  const outerClasses = classNames("hero section");

  return (
    <div>
      {!!user && <Topcontent user={user}></Topcontent>}

      <section className={outerClasses} style={{ marginTop: "4rem" }}>
        <div className="container">
          <h3>Frequently Asked Questions About WeeklyPeek Messaging</h3>

          <h5>How does this work?</h5>

          <p>
            With WeeklyPeek messaging, you create or join a group with other
            people. Once a week you must send an update to the group, and so
            does everyone else. Then at the end of the week, you'll receive an
            email with all the other responsees in your email inbox. Not patient
            enough? You can always open the WeeklyPeek web app and read the
            other members' responses right here!
          </p>

          <h5>Does this store my data?</h5>
          <p>
            We store your name and email address. We use these to send you the
            weekly responses. However, please use any nickname you'd like! You
            can also choose to have your messages deleted right after they are
            sent to the other group members.
          </p>

          <h5>Are my messages encrypted?</h5>
          <p>
            Yes! We fully encrypt all messages sent through weeklypeek with
            industry standard encryption. The only people reading your messages
            are those who have been invited to your group.
          </p>

          <h5>Does this cost money?</h5>
          <p>
            Nope, WeeklyPeek messaging is Free for up to 3 different groups.
          </p>

          <h5>I want to delete my account. Can I do that?</h5>
          <p>
            We're sorry to hear that, but of course you can. Please send an
            email to <strong>william@weeeklypeek.com</strong> to delete your
            account. We will make this even easieer to do from your settings
            page as soon as we can! Wheen wee delete your account we'll wipe all
            your data from our server!
          </p>

          {!user && (
            <div>
              <h5>Ready to Get Started?</h5>
              <div>
                <Button
                  tag="a"
                  color="primary"
                  href="/signup/messaging"
                  wideMobile
                >
                  Sign Up
                </Button>
              </div>
              <div>
                <Link href="/login/messaging" variant="body2">
                  Already have an account? Sign in
                </Link>
              </div>
            </div>
          )}

          {!!user && (
            <Button
              tag="a"
              color="primary"
              href="/messaging/threads"
              wideMobile
            >
              Go to My Threads
            </Button>
          )}
        </div>
      </section>
    </div>
  );
}
