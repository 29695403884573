import React from "react";
import { useValues } from "kea";
import "./App.css";
import Login from "./containers/Login";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import ReactGA from "react-ga";

import PlaidLinkComp from "./components/Link";
import SignUp from "./containers/Signup";
import Profile from "./containers/Profile";
import Home from "./containers/Home";
import Footer from "./components/layout/Footer";
import MyWeekPage from "./containers/MyWeek";
import Header from "./components/layout/Header";
import AppRoute from "./utils/AppRoute";
import LayoutDefault from "./layouts/LayoutDefault";

import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import Sidebar from "./layouts/Sidebar";
import { Topcontent } from "./layouts/Topcontent";

import { userLogic } from "./userLogic";

function initializeReactGA() {
  ReactGA.initialize("UA-155323455-1");
}

function FinanceApp() {
  initializeReactGA();

  const { user } = useValues(userLogic);

  if (!user) {
    return (
      <Router>
        <AppRoute path="/login" exact={true} component={Login} />
        <AppRoute path="/signup" exact={true} component={SignUp} />
        <Route path="/" exact={true}>
          <Home />
          <Footer></Footer>
        </Route>
      </Router>
    );
  }

  return (
    <Router>
      <Route path="/start/:user/:token" component={PlaidLinkComp} />
      <Route path="/start/" exact={true} component={PlaidLinkComp} />
      <AppRoute path="/login" exact={true} component={Login} />
      <AppRoute path="/signup" exact={true} component={SignUp} />
      <AppRoute path={"/profile"} component={Profile} layout={LayoutDefault} />
      <AppRoute
        path={"/my_week"}
        component={MyWeekPage}
        layout={LayoutDefault}
      />
      <Route path="/" exact={true}>
        <Home />
        <Footer></Footer>
      </Route>
    </Router>
  );
}

export default FinanceApp;
