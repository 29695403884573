import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import axios from "axios";
import { api_url } from "../../constants";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

import Cookie from "js-cookie";
import WPFApi from "../../api/WPFApi";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      WeeklyPeek, Inc ({new Date().getFullYear()}){"."}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#174f7c"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#174f7c"
  }
}));

export default function MSGLogin() {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function handleSubmit(event) {
    event.preventDefault();

    const selectedApp = "msg_wp";

    const loginData = {
      email: email,
      password: password,
      selected_app: selectedApp
    };

    axios
      .post(`${api_url}/auth/login`, loginData)
      .then(function(response) {
        const authToken = response.data["access_token"];
        WPFApi.setToken(authToken);
        console.log("Logged in to messaging!");

        setTimeout(function() {
          window.location = "/messaging/threads";
        }, 500);
      })
      .catch(function(error) {
        toast.error(error.response.data.error);
      });
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in to WeeklyPeek Messaging
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onInput={e => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onInput={e => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>

          <Link href="/signup/messaging" variant="body2">
            {"Don't have an account? Sign Up"}
          </Link>

          {/*<Grid container>*/}
          {/*    <Grid item xs>*/}
          {/*        <Link href="/reset-password" variant="body2">*/}
          {/*            Forgot password?*/}
          {/*        </Link>*/}
          {/*    </Grid>*/}
          {/*    <Grid item>*/}
          {/*        <Link href="/signup" variant="body2">*/}
          {/*            {"Don't have an account? Sign Up"}*/}
          {/*        </Link>*/}
          {/*    </Grid>*/}
          {/*</Grid>*/}
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
