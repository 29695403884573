import React, { Component, isValidElement } from "react";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import MessagingApi from "../../api/MessagingApi";

export class InviteToGroup extends Component {
  urlRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      userEmail: "",
      isLoading: false
    };
    this.sendEmailInvite = this.sendEmailInvite.bind(this);
    this.sendEmailInvite = this.sendEmailInvite.bind(this);
  }

  copyToClipboard = () => {
    this.urlRef.current.focus();
    this.urlRef.current.select();
    document.execCommand("copy");
    this.urlRef.current.blur();
    toast("Link copied to clipboard");
  };

  onChange = event => {
    const value = event.target.value;
    this.setState({
      userEmail: value
    });
  };

  isValid = email => {
    if (email == undefined) {
      return false;
    }
    console.log(email.indexOf("."));
    return (
      email.length > 0 &&
      email.indexOf("@") > 0 &&
      email.indexOf(".") < email.length - 1
    );
  };

  sendEmailInvite() {
    this.setState({ isLoading: true });
    const inviteData = {
      group_id: this.props.groupId,
      email: this.state.userEmail
    };
    const self = this;
    MessagingApi.sendEmailInvite(
      inviteData,
      function(response) {
        toast("Invite sent!");
        self.props.onInviteSent();
      },
      function(err) {
        toast.error(
          "Issue occured sending invite, please check the email address."
        );
      }
    );
  }

  render() {
    let url =
      window.location.origin == "https://app.weeklypeek.com"
        ? "https://t.weeklypeek.com"
        : window.location.origin;

    const validEmail = this.isValid(this.state.userEmail);

    return (
      <div className="modal-wrapper">
        <h4>Send an invite for this group</h4>
        <br />
        <div className="input-group">
          <input
            type="text"
            placeholder="Enter email here"
            className="form-control"
            style={{ borderRadius: "4px 4px 4px 4px" }}
            value={this.state.userEmail}
            onChange={this.onChange.bind(this)}
          />
          <div style={{ width: "100%", textAlign: "center", margin: "10px" }}>
            <Button
              className="btn btn-success"
              color="success"
              size="sm"
              disabled={
                !this.isValid(this.state.userEmail) || this.state.isLoading
              }
              onClick={() => this.sendEmailInvite()}
            >
              Send
            </Button>
          </div>
        </div>

        {/* <br></br>
        <hr />
        Or Send your friend the following URL:
        <br />
        <br />
        <div className="input-group" onClick={() => this.copyToClipboard()}>
          <Button
            className="btn"
            style={{
              borderRadius: "4px 0px 0px 4px",
              border: "1px solid #007bff"
            }}
            variant="link"
            size="sm"
          >
            Copy
          </Button>
          <input
            type="text"
            ref={this.urlRef}
            className="form-control"
            value={!!this.props.inviteLink ? this.props.inviteLink : "error..."}
          />
        </div>
        <br /> */}
      </div>
    );
  }
}
