import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

export default function Title(props) {
    return (
        <div style={{marginTop: '24px'}}>
            <Typography component="h2" variant="h5" color="primary" gutterBottom>
                {props.children}
            </Typography>
        </div>
    );
}

Title.propTypes = {
    children: PropTypes.node,
};
