import React, { Component } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import MessagingApi from "../../api/MessagingApi";

export class CreateGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      threads: [],
      validated: false,
      formControls: {
        desc: {
          value: ""
        },
        name: {
          value: ""
        }
      }
    };
  }

  handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    this.setState({ validated: true });

    event.preventDefault();
    event.stopPropagation();

    const self = this;
    MessagingApi.createNewGroup(
      {
        group_name: this.state.formControls.name.value,
        description: this.state.formControls.desc.value
      },
      function(group) {
        self.props.history.push(`/messaging/threads`);
      },
      function(error) {
        console.log(error);
      }
    );
  }

  changeHandler = event => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      formControls: {
        ...this.state.formControls,
        [name]: {
          ...this.state.formControls[name],
          value
        }
      }
    });
  };

  render() {
    const { validated, formControls } = this.state;
    return (
      <div className="app-page">
        <h3>Create a WeeklyPeek Group</h3>
        <div style={{ marginTop: "1rem", textAlign: "left" }}>
          <Form
            noValidate
            validated={validated}
            onSubmit={this.handleSubmit.bind(this)}
          >
            <Form.Group controlId="formGroupName" className="form-row">
              <Form.Label>Group Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Name for your new group"
                name="name"
                value={formControls.name.value}
                onChange={this.changeHandler.bind(this)}
              />
            </Form.Group>

            <Form.Group controlId="formDescription" className="form-row">
              <Form.Label>Group Description</Form.Label>
              <Form.Control
                required
                className="form-control"
                type="text"
                placeholder="Something about this group"
                name="desc"
                value={formControls.desc.value}
                onChange={this.changeHandler.bind(this)}
              />
            </Form.Group>

            <p className="muted text-ssecondary" style={{ fontSize: "14px" }}>
              After you create a group, you'll invite other members with their
              emails to start sending weekly messages
            </p>

            <Button variant="primary" type="submit" size="sm">
              Submit
            </Button>
            <Link to="/messaging/threads">
              <Button variant="link" size="sm">
                Cancel
              </Button>
            </Link>
          </Form>
        </div>
      </div>
    );
  }
}
