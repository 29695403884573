import React from "react";
import { useValues } from "kea";
import "./App.css";
import Login from "./containers/Login";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation
} from "react-router-dom";

import ReactGA from "react-ga";

import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import Sidebar from "./layouts/Sidebar";
import { Topcontent } from "./layouts/Topcontent";
import { ThreadList } from "./containers/threads/ThreadsList";
import MSGLogin from "./containers/auth/MSGLogin";
import MSGSignup from "./containers/auth/MSGSignup";
import { AboutPage } from "./containers/AboutPage";
import { Setup } from "./containers/setup/Setup";
import { CreateGroup } from "./containers/groups/CreateGroup";
import { GroupDetails } from "./containers/groups/GroupDetails";
import InvitePage from "./containers/invite/InvitePage";

import { userLogic } from "./userLogic";
import WPFApi from "./api/WPFApi";

function initializeReactGA() {
  ReactGA.initialize("UA-155323455-2");
}

function PrivateRoute({ component: Component, ...props }) {
  if (!window.location.href.includes("localhost")) {
    ReactGA.pageview(props.path);
  }
  return (
    <Route
      path={props.path}
      exact={props.exact}
      render={routeProps => {
        return <Component {...props} {...routeProps} />;
      }}
    />
  );
}

function MessagingApp() {
  initializeReactGA();

  const { user } = useValues(userLogic);

  if (!user) {
    return (
      <Router>
        <Route path="/login/messaging" component={MSGLogin}></Route>
        <Route path="/signup/messaging" component={MSGSignup}></Route>
        <Route path="/about/messaging" component={AboutPage}></Route>

        <ToastContainer
          autoClose={5000}
          transition={Slide}
          position="bottom-left"
        ></ToastContainer>
      </Router>
    );
  }

  return (
    <Router>
      <Route path="/messaging">
        <div className="container-fluid flex-grow-1 d-flex">
          <div className="row flex-fill flex-column flex-sm-row">
            <Sidebar user={user} />

            <div className="col-sm-12 col-md-10 col-md-offset-2">
              <Topcontent user={user} />

              <div style={{ marginTop: "1rem" }}>
                <PrivateRoute
                  path="/messaging"
                  exact
                  component={ThreadList}
                  user={user}
                />
                <PrivateRoute
                  path="/messaging/threads"
                  exact
                  component={ThreadList}
                  user={user}
                />
                <PrivateRoute
                  path="/messaging/setup"
                  component={Setup}
                  user={user}
                />
                <PrivateRoute
                  path="/messaging/groups"
                  component={CreateGroup}
                  user={user}
                />
                <PrivateRoute
                  path="/messaging/groups/:groupId"
                  component={GroupDetails}
                  user={user}
                />
                <Route
                  path="/messaging/login"
                  component={MSGLogin}
                  exact={true}
                ></Route>
              </div>
            </div>
            <ToastContainer
              autoClose={8000}
              transition={Slide}
              position="bottom-center"
            ></ToastContainer>
          </div>
        </div>
      </Route>
      <PrivateRoute
        path="/invite/:groupShort"
        exact={false}
        component={InvitePage}
        user={user}
      ></PrivateRoute>
      <Route path="/signup/messaging" component={MSGSignup}></Route>
      <Route path="/about/messaging" component={AboutPage}></Route>
      <Route path="/login/messaging" component={MSGLogin}></Route>
    </Router>
  );
}

export default MessagingApp;
