//import {api_url} from "../constants";
import axios from "axios";
import Cookie from "js-cookie";
import WPFApi from "./WPFApi";

const api_url = process.env.REACT_APP_API_URL;

export default class MessagingApi {
  static getUserInfo(handler) {
    axios.get(`${api_url}/user`, WPFApi.getConfig()).then(function(response) {
      //WPFApi.check_logged_in(response)
      handler(response.data);
    });
  }

  static getUserGroups(handler) {
    axios
      .get(`${api_url}/messaging/groups`, WPFApi.getConfig())
      .then(function(response) {
        //WPFApi.check_logged_in(response)
        handler(response.data);
      });
  }

  static getUserThreads(handler, onError) {
    axios
      .get(`${api_url}/messaging/threads/active`, WPFApi.getConfig())
      .then(function(response) {
        //WPFApi.check_logged_in(response)
        handler(response.data);
      })
      .catch(function(error) {
        onError(error);
      });
  }

  static getThreadDetails(threadId, handler) {
    axios
      .get(`${api_url}/messaging/threads/${threadId}`, WPFApi.getConfig())
      .then(function(response) {
        //WPFApi.check_logged_in(response)
        handler(response.data);
      });
  }

  static getGroupDetails(groupId, handler, onError) {
    axios
      .get(`${api_url}/messaging/groups/${groupId}`, WPFApi.getConfig())
      .then(function(response) {
        console.log("group details");
        console.dir(response.data);
        handler(response.data);
      })
      .catch(function(error) {
        onError(error);
      });
  }

  static createNewGroup(groupData, onSuccess, onError) {
    axios
      .post(`${api_url}/messaging/groups`, groupData, WPFApi.getConfig())
      .then(function(response) {
        onSuccess(response.data);
      })
      .catch(function(error) {
        console.log(error);
        onError(error);
      });
  }

  static submitNewMessage(messageData, onSuccess, onError) {
    axios
      .post(
        `${api_url}/messaging/messages/new_message`,
        messageData,
        WPFApi.getConfig()
      )
      .then(function(response) {
        onSuccess(response.data);
      })
      .catch(function(error) {
        console.log("new message error!");
        console.log(error);
        onError(error);
      });
  }

  static addMembersToGroup(inviteData, onSuccess, onError) {
    axios
      .post(`${api_url}/groups/invites`, inviteData, WPFApi.getConfig())
      .then(function(response) {
        console.log("Group Invite sent!");
        onSuccess(response.data);
      })
      .catch(function(error) {
        console.log(error);
        onError(error);
      });
  }

  static sendEmailInvite(inviteData, onSuccess, onError) {
    axios
      .post(
        `${api_url}/messaging/groups/invite`,
        inviteData,
        WPFApi.getConfig()
      )
      .then(function(response) {
        console.log("Group Invite sent!");
        onSuccess(response.data);
      })
      .catch(function(error) {
        console.log(error);
        onError(error);
      });
  }

  static getInviteInfo(groupShort, inviteId, onSuccess, onError) {
    axios
      .get(`${api_url}/messaging/groups/invite/${groupShort}/${inviteId}`)
      .then(function(response) {
        onSuccess(response.data);
      })
      .catch(function(error) {
        console.log(error);
        onError(error);
      });
  }
}
