import React, { Component } from "react";
import { toast } from "react-toastify";

export class InviteTeam extends Component {
  urlRef = React.createRef();
  copyToClipboard = () => {
    this.urlRef.current.focus();
    this.urlRef.current.select();
    document.execCommand("copy");
    this.urlRef.current.blur();
    toast("Link copied!");
  };
  render() {
    let urlTODO =
      window.location.origin == "https://app.weeklypeek.com"
        ? "https://t.weeklypeek.com"
        : window.location.origin;
    let url = "https://weeklypeek.com";

    return (
      <div>
        <h4>Invite a friend to WeeklyPeek</h4>
        <br />
        Send your friend the following URL:
        <br />
        <br />
        <div className="input-group">
          <input
            type="text"
            ref={this.urlRef}
            className="form-control"
            value={url}
          />
        </div>
        <br />
      </div>
    );
  }
}
