import React, { Component } from "react";
import { Link, useLocation } from "react-router-dom";
import MessagingApi from "../../api/MessagingApi";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
//import Button from "../../components/elements/Button";

import axios from "axios";
import { api_url } from "../../constants";
import WPFApi from "../../api/WPFApi";

import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { toast } from "react-toastify";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const inlineCss = {
  alignItems: "flex-start",
  minHeight: "492px"
};

const inlineStyle = function() {
  if (window.innerWidth > 641) {
    return inlineCss;
  }
};

const STEP_INVITED = 0;
const STEP_SIGNUP = 1;
const STEP_LOGIN = 2;
const STEP_ACCEPT = 3;

export default class InvitePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      groupShort: this.props.match.params.groupShort,
      selected: "",
      user: this.props.user,
      inviteStep: STEP_INVITED
    };

    const params = new URLSearchParams(this.props.location.search);
    this.state.inviteId = params.get("invite_id");
    this.renderInviteStep = this.renderInviteStep.bind(this);
    this.getInviteInfo = this.getInviteInfo.bind(this);
    this.handleSubmitSignup = this.handleSubmitSignup.bind(this);
    this.handleAcceptInvite = this.handleAcceptInvite.bind(this);
  }

  getInviteInfo() {
    const self = this;
    MessagingApi.getInviteInfo(
      this.state.groupShort,
      this.state.inviteId,
      function(response) {
        self.setState({ inviteInfo: response, isLoading: false });
      },
      function(error) {
        toast.error(error);
      }
    );
  }

  componentDidMount() {
    this.getInviteInfo();
  }

  // LOGIN
  handleLoginSubmit(event) {
    event.preventDefault();

    const loginData = {
      email: this.state.email,
      password: this.state.password,
      selected_app: "msg_wp"
    };

    const self = this;
    axios
      .post(`${api_url}/auth/login`, loginData)
      .then(function(response) {
        const authToken = response.data["access_token"];
        WPFApi.setToken(authToken);
        self.setState({ inviteStep: STEP_ACCEPT });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  handleSubmitSignup(event) {
    event.preventDefault();

    const signupData = {
      email: this.state.email,
      name: this.state.name,
      password: this.state.password,
      selected_app: "msg_wp"
    };

    const self = this;
    axios
      .post(`${api_url}/auth/signup`, signupData)
      .then(function(response) {
        const authToken = response.data["access_token"];
        WPFApi.setToken(authToken);

        self.setState({ inviteStep: STEP_ACCEPT });
      })
      .catch(function(error) {
        console.log(error);
        toast.error(
          "Could not sign up with that info. Please check you have filled out the form and that you have not already signed up with that email."
        );
      });
  }

  setEmail(e) {
    this.setState({ email: e });
  }

  setPassword(e) {
    this.setState({ password: e });
  }

  setName(e) {
    this.setState({ name: e });
  }

  handleAcceptInvite(accepted) {
    const { inviteInfo } = this.state;
    const responseData = { accept: accepted };
    const self = this;
    axios
      .post(
        `${api_url}/messaging/invites/${inviteInfo.id}`,
        responseData,
        WPFApi.getConfig()
      )
      .then(function(response) {
        //reditect to messaging / threads
        setTimeout(function() {
          window.location = "/messaging/threads";
        }, 500);
      })
      .catch(function(error) {
        console.log(error);
        toast.error(error);
      });
  }

  renderLoginPanel() {
    const { inviteInfo } = this.state;
    return (
      <div className="hero-content split-item-content center-content-mobile">
        <h1 className="mt-16 mb-16 reveal-from-top" data-reveal-delay="150">
          Login To Accept {inviteInfo.invitee_name}
          {"'"}s Invite to {inviteInfo.group_name}
        </h1>

        <form noValidate onSubmit={this.handleSubmitLogin}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={this.state.email}
            onInput={e => this.setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={this.state.password}
            onInput={e => this.setPassword(e.target.value)}
          />
          <Button type="submit" fullWidth variant="contained" color="primary">
            Log In
          </Button>
        </form>
      </div>
    );
  }

  renderSignupPanel() {
    const { inviteInfo } = this.state;
    return (
      <div>
        <h2 className="mt-16 mb-16 reveal-from-top" data-reveal-delay="150">
          Sign Up to Join WeeklyPeek and Accept {inviteInfo.invitee_name}
          {"'"}s Invite to {inviteInfo.group_name}
        </h2>
        <form noValidate onSubmit={this.handleSubmitSignup}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="name"
            label="Your Name"
            name="name"
            autoComplete="name"
            value={this.state.name}
            onInput={e => this.setName(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={this.state.email}
            onInput={e => this.setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={this.state.password}
            onInput={e => this.setPassword(e.target.value)}
          />
          <Button type="submit" variant="contained" color="primary">
            Sign Up
          </Button>
        </form>
      </div>
    );
  }

  renderAcceptStep() {
    const { inviteInfo } = this.state;

    return (
      <div className="invite-panel">
        {inviteInfo && (
          <p>
            <strong>{inviteInfo.invitee_name}</strong> has invited you to join
            their weekly messaging group: {inviteInfo.group_name}
          </p>
        )}

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => {
                this.handleAcceptInvite(true);
              }}
            >
              Accept
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              size="medium"
              onClick={() => {
                this.handleDeclineInvite(false);
              }}
            >
              Decline
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }

  renderInvitedPanel() {
    const { inviteInfo } = this.state;
    return (
      <div className="hero-content split-item-content center-content-mobile">
        <h1 className="mt-16 mb-16 reveal-from-top" data-reveal-delay="150">
          You've been invited to WeeklyPeek
        </h1>
        <p className="mt-0 mb-24 reveal-from-top" data-reveal-delay="300">
          {inviteInfo && (
            <p>
              <strong>{inviteInfo.invitee_name}</strong> has invited you to join
              their weekly messaging group: {inviteInfo.group_name}
            </p>
          )}
        </p>
        <div className="reveal-from-top" data-reveal-delay="450">
          <div>
            <Button
              color="primary"
              wideMobile
              onClick={() => {
                this.setState({ inviteStep: STEP_SIGNUP });
              }}
            >
              Get started
            </Button>
          </div>
          <div>
            <Link
              variant="body2"
              onClick={() => {
                this.setState({ inviteStep: STEP_LOGIN });
              }}
            >
              Already have an account? Sign in to accept this invite
            </Link>
          </div>
        </div>
      </div>
    );
  }

  renderInviteStep() {
    const { user } = this.props;

    if (!!user && user.id) {
      return this.renderAcceptStep();
    }

    switch (this.state.inviteStep) {
      case STEP_INVITED:
        return this.renderInvitedPanel();
      case STEP_LOGIN:
        return this.renderLoginPanel();
      case STEP_SIGNUP:
        return this.renderSignupPanel();
      case STEP_ACCEPT:
        return this.renderAcceptStep();
    }
  }

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const { isLoading } = this.state;

    const outerClasses = classNames(
      "hero section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "hero-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const splitClasses = classNames(
      "split-wrap",
      invertMobile && "invert-mobile",
      invertDesktop && "invert-desktop",
      alignTop && "align-top"
    );

    return (
      <section {...props} className={outerClasses}>
        <div className="invite-container illustration-section-01">
          <div className="posthog-title" style={{ paddingTop: "1rem" }}>
            WeeklyPeek
          </div>
          <div className={innerClasses}>
            <div className={splitClasses}>
              <div className="split-item" style={inlineStyle()}>
                {!isLoading && this.renderInviteStep()}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
